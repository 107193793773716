import Replay from "../../assets/images/Replay.png";
import ResouceLibary4 from "../../assets/images/ResourceLibraryClean.png";
import img4 from "../../assets/images/LearnMore4.png";
import img5 from "../../assets/images/LearnMore5.png";
import FolderImg from "../../assets/images/FolderLogoClean.png";
import MyDocumentTile from "../MyDocumentTile";
import pdfFile from "../../assets/pdf/Inception_Financial_Client_Presentation_-_08.22.20.pdf";
import img6 from "../../assets/images/solar-study-favicon.png";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

interface Props {
  openOverlay: (url: string) => void;
  openAnimatedOverview: (url: string) => void;
  openOverlay1: (componentData: any) => void;
}

const Tile1 = ({ openOverlay, openAnimatedOverview, openOverlay1 }: Props) => {
  const solarStudyInvite = useSelector(
    (state: RootState) => state.login.solarStudyInvite
  );
  return (
    <div
      className={`bg-[#FEFCFB] rounded-xl w-full pt-1 shadow-lg h-[390px] overflow-y-auto`}
    >
      <h2 className="text-[25px] text-black fond-bold p-1 mb-[8px]">
        Learn More
      </h2>
      <div className="grid gap-y-3 grid-cols-3 border-t-4 border-[#EFEFF4] p-2 pt-4">
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openOverlay(
              "https://learn.zohopublic.com/external/manual/inception-financial-knowledge-base?p=bf80f12473e7535675946df5b62034cc7fb015d0047609dbd60961acab25116f"
            )
          }
        >
          <img className="h-[50px]" src={ResouceLibary4} alt="" />
          <h3 className="text-center text-[13px] mt-4">KNOWLEDGE CENTER</h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openOverlay(
              "https://drive.google.com/file/d/16LFGBJBoD4jGzcbcR4vbrXV8jJFQjGKX/preview"
            )
          }
        >
          <img className="h-[50px]" src={img4} alt="" />
          <h3 className="text-center text-[13px] mt-4">ONE PAGE OVERVIEW</h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => openOverlay(pdfFile)}
        >
          <img className="h-[50px]" src={img5} alt="" />
          <h3 className="text-center text-[13px] mt-4">
            CLIENT PRESENTATION PPT
          </h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openAnimatedOverview(
              "https://player.vimeo.com/video/949543563?h=1cc197bf6a&badge=0&autopause=0&amp;&player_id=0&app_id=58479"
            )
          }
        >
          <img className="w-[75px]" src={Replay} alt="" />
          <h3 className="text-center text-[13px]">ANIMATED OVERVIEW</h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openAnimatedOverview(
              "https://player.vimeo.com/video/1001799796?h=92ddd84060&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            )
          }
        >
          <img className="h-[75px]" src={Replay} alt="" />
          <h3 className="text-center text-[13px]">CLIENT PRESENTATION VIDEO</h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openAnimatedOverview(
              "https://player.vimeo.com/video/955081780?h=7c048b1e7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            )
          }
        >
          <img className="h-[75px]" src={Replay} alt="" />
          <h3 className="text-center text-[13px]">PRO FORMA REVIEW</h3>
        </div>
        {solarStudyInvite && (
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => window.open("https://solar.study/", "_blank")}
          >
            <img className="h-[65px]" src={img6} alt="" />
            <h3 className="text-center text-[13px]">SOLAR STUDY</h3>
          </div>
        )}
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openOverlay1(
              <MyDocumentTile
                folder={{ id: "1rKmZ2sOsOCuJcRGvddP54yZGJsAXLetc" }}
                type="FromLearnMore"
                heading="Sample Asset Documents"
              />
            )
          }
        >
          <img className="h-[50px]" src={FolderImg} alt="" />
          <h3 className="text-center text-[13px] mt-4">
            SAMPLE ASSET DOCUMENTS
          </h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openOverlay1(
              <MyDocumentTile
                folder={{ id: "1f6aIYHRSphrs6BteWYXBmzR5vPLfLATF" }}
                type="FromLearnMore"
                showSidebar={false}
                heading="Sample Agreements"
              />
            )
          }
        >
          <img className="h-[50px]" src={FolderImg} alt="" />
          <h3 className="text-center text-[13px] mt-4">SAMPLE AGREEMENTS</h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openOverlay1(
              <MyDocumentTile
                folder={{ id: "1TJmrVANBJgeKeNDTnKx3PD_Qt74ZDrqU" }}
                type="FromLearnMore"
                showSidebar={false}
                heading="Sample Tax Documents"
              />
            )
          }
        >
          <img className="h-[50px]" src={FolderImg} alt="" />
          <h3 className="text-center text-[13px] mt-4">SAMPLE TAX DOCUMENTS</h3>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() =>
            openAnimatedOverview(
              "https://vimeo.com/1046949461/2c31de84e8?share=copy"
            )
          }
        >
          <img className="w-[75px]" src={Replay} alt="" />
          <h3 className="text-center text-[13px]">
            Material Participation Overview
          </h3>
        </div>
        {/* <div
              className={styles.tile1Box}
              onClick={() =>
                openOverlay(
                  "https://learn.zohopublic.com/external/manual/inception-financial-sample-tax-forms?p=bf80f12473e7535675946df5b62034ccbdad2a880b0a94901c686bd8bc9127c4"
                )
              }
            >
              <img className={styles.tile1Img} src={Replay} alt="" />
              <h3 className={styles.tileheading}>SAMPLE TAX FORMS</h3>
            </div> */}
      </div>
    </div>
  );
};

export default Tile1;
